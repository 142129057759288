import { Attachment } from '../../../state/ducks/attachments/types';
import { ChangeRequest } from '../../../state/ducks/changeRequest/types';
import { Id } from '../../../state/ducks/common/types';
import { DocumentRevisionDocument, DocumentRevisionStatus, FBDocumentRevisionProps, RevisionChangeType } from '../../../state/ducks/documentRevisions/types';
import { OptionType } from '../../components/forms/fields/Autocomplete/types';
import { FBSchemaProps } from '../../form.builder';

export interface DocumentRevisionFormValues extends FBDocumentRevisionProps {
  version?: number
  id?: string
  description: string
  name: string
  attachments: Attachment[]
  referenceTo: OptionType[]
  document?: Partial<DocumentRevisionDocument>
  revisionChangeType?: RevisionChangeType
  changeRequest?: ChangeRequest
  retrain?: boolean
  status?: DocumentRevisionStatus
  securityEmployees: OptionType[]
  securityGroups: OptionType[]
  operators: OptionType[]
  outputRevision?: boolean
  renderHTML?: string
  outputDocumentTypes?: OptionType[]
  schema?: FBSchemaProps[]
  parentParts?: Array<{ id: string }>
  parentEquipment?: {id: string}
  childrenParts?: Array<{ id: string }>
  equipmentSection?: string
  redline?: boolean
}

export interface ApproveAndReleaseFormValues {
  approvals: OptionType[]
  password: string
  formDocument: Id
  documentType: Id
}

export enum FBDataStoreApprovalKeys {
  PO_APPROVALS='poApprovals',
  INLINE_APPROVALS='inlineApprovals',
}
