import { MODE_FIELD } from '../../components/KendoDataGrid/constants';
import { Currency } from './types';

export const DEFAULT_CURRENCY = Currency.USD;

export const EMPTY_VALUE_PLACEHOLDER = '-';

export const SUPPLIER_FIELD = 'supplierName';
export const SUPPLIER_STATUS_FIELD = 'supplierStatus';

export const FIELDS_TO_OMIT = [MODE_FIELD, SUPPLIER_FIELD, SUPPLIER_STATUS_FIELD];
